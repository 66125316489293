@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;800&family=Source+Sans+Pro:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6fcf8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Open Sans', sans-serif;
}

.carou {
  width: 100%;
  .carou-item {
    // background-color: white;
    border-radius: 4px;
    max-width: 400px;
    height: 100%;
  }
}

.logo {
  width: 140px;
  height: auto
}

p {
  color: black;
}

body {
  h1 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 30px;
  }
  #hero {
    h2{
      font-size: 42px;
      font-weight: 800;
      width: 100%;
      text-align: left;
      margin-bottom: 30px;
    }
    p {
      font-size: 22px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 50px;

    }
    .orange_btn{
      text-align: left;
      margin-bottom: 20px;
    }
    .padd {
      max-width: 1500px;
      margin: auto;
    }
  }
  main{
    max-width: 1500px;
    padding: 20px 0px;
    margin: auto;
  }
.usp-bar{
  display: flex;
  flex-wrap: wrap;
  .atom-flex {
    display: flex;
    justify-content: center;
    margin: 32px 0px;
  }
  .usp-card {
    background-color: white;
    border-radius: 8px;
    width: 450px;
    margin: 16px;
    padding: 16px 8px;
    // height: 400px;
    // border: 1px solid green;
    h1 {
      font-size: 16px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 16px;
    }
    p {
      text-align: center;
    }
}
}  

  .home_image {
    width: 750px;
    height: auto;
    border-radius: 25px;
  }
  .orange_btn {
    background-color: #ffb32d;
    padding: 12px 20px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
  .home_flex {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 75px 0px;
    div {
      margin: 0px 16px;
    }
  }
  .home_flex:nth-child(even){
    flex-direction: row-reverse;
  }
}

.team-flex{
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  .team-text {
    max-width: 400px;
  }
  // .team-image {
  //   background-image: url('../src/img/home/team_wallpaper_img.png');
  //   width: 400px;
  //   height: 400px;
  //   position: relative;
  //   right: 0;
  //   top: 0;
  // }
}

.team-wrapper {
  display: flex;
  flex-wrap: wrap;
  .team-card {
    max-width: 400px;
    margin: 8px;
    img {
      border-radius: 8px;
      max-width: 255px;
      height: auto;
    }
    .team-name {
      font-weight: 600;
    }
    .team-contact {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
    }
  }
}

header{
  background-color: #FFF;
  a {
    text-decoration: none;
    color: black;
    margin: 0 8px;
  }:hover{
    text-decoration: underline;
  }
  .mx-width {
    max-width: 1500px;
    margin: auto;
    display: flex;
    padding: 16px;
    justify-content: space-between;
  }
}

.total-footer {
  padding-top: 32px;
  background-color: white;
  // display: flex;
  // flex-wrap: wrap;
  .contact-name {
    font-weight: 600;
  }
  @media (max-width:900px) {
    .top-footer {
      max-width: 100%;
      flex-direction: column;
    }
  }
  .top-footer{
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    padding: 20px 0px;
    justify-content: space-between;
    margin: auto;
    .contact, .info {
      width: 40%;
    }
    .footer-team {
      display: flex;
      justify-content: space-between;
      .team-name {
        font-weight: bold;
      }
    }
  }
}
.App-footer {
  background-color: #454545;
  *{
    color: white;
    max-width: 1400px;
    margin: auto;
    text-align: center;
  }
  width: 100%;
  padding: 32px 0px;
}

@media (max-width:900px) {
  #hero {
    width: 100% !important;
    h2 {
      margin: auto !important;
      font-size: 22px !important;
      text-align: center !important;
      margin-bottom: 16px !important;
    }
    p {
      font-size: 16px !important;
      text-align: center !important;

    }
  }
    body {
      padding: 16px;
      .home_flex {
        flex-direction: column;
    }
    .home_flex:nth-child(even){
      flex-direction: row;
    }
    h1 {
      margin-left: 16px;
    }
    main: {
      padding: 20px;
    }
    .home_image {
      width: 100%;
      height: auto;
      border-radius: 25px;
    }
  }
  iframe {
    max-width: 100%;
  }
  .total-footer .top-footer .contact, .total-footer .top-footer .info{
    width: 100%;
  }
  .App-header {
    a {
      display: none;
    }
  }
  header .mx-width {
    justify-content: center;
  }
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  padding: 8px;
  margin: 8px 0px;
  width: 100%;
}
button {
  background-color: #ffb32d;
  padding: 12px 20px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.certificates {
  display: flex;
  // margin-top: 120px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  .certificate {
    // width: 100%;
    width: auto;
    // max-width: 120px;
    // height: 100%;
    max-height: 120px;
  }
}